// src/components/ChatComponent.js
import React, { useState, useRef, useEffect } from "react";
import { baseDevasUrl } from "../../utils/constants";
import {
  Box,
  Flex,
  Input,
  Text,
  IconButton,
  keyframes,
  VStack,
  HStack,
  Heading,
  Divider,
  useColorModeValue,
  useColorMode,
  Spacer,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  List,
  ListItem,
  ListIcon,
  Icon,
  Image,
  Textarea,
  Tooltip,
  createIcon
} from "@chakra-ui/react";
import WebSocketSingleton from "./../../utils/websocket-singleton";
import {
  SunIcon,
  MoonIcon,
  ArrowForwardIcon,
  HamburgerIcon,
  AddIcon,
  AttachmentIcon,
  CloseIcon,
} from "@chakra-ui/icons";

const wave = keyframes`
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.0);
  }
`;

const progresswave = keyframes`
    0% {
    background-position: 300% 300%;
  }
  100% {
    background-position: -300% -300%;
  }
`;

const ChatComponent = ({
  chatConfig,
  setchatConfig, 
  inputParser,
  outputParser,
  inputmessageParser,
  renderMessages,
  chatMessages,
  setChatMessages,
  loadThreadMessages,
  createChatThread,
  pastThreads,
  setPastThreads,
  setMarkdownContent
}) => {
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showPastThreads, setShowPastThreads] = useState(false); // State to manage showing past threads
  const { toggleColorMode, setColorMode } = useColorMode();
  const messagesEndRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage side panel visibility
  const [newThreadTitle, setnewThreadTitle] = useState("");
  const [createNewThread, setcreateNewThread] = useState(false);
  const [newThreadsendMessage, setNewThreadsendMessage] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [progressMessage, setProgressMessage] = useState(""); // Progress message state

  const lineHeightEm = 1.5; // Adjust based on your design
  const maxLines = 4;
  const maxHeight = `${lineHeightEm * maxLines}em`; // 4 lines max
  const minHeight = `${lineHeightEm}em`; // 1 line high

  const LIGHTBGCOLOR = chatConfig?.colors?.background?.light || "gray.100";
  const DARKBGCOLOR = chatConfig?.colors?.background?.dark || "gray.800";

  const LIGHTUSERMESSAGEBGCOLOR =
    chatConfig?.colors?.userMessageBackground?.light || "green.100";
  const DARKUSERMESSAGEBGCOLOR =
    chatConfig?.colors?.userMessageBackground?.dark || "green.800";

  const LIGHTASSISTANTMESSAGEBGCOLOR =
    chatConfig?.colors?.assistantMessageBackground?.light || "blue.100";
  const DARKASSISTANTMESSAGEBGCOLOR =
    chatConfig?.colors?.assistantMessageBackground?.dark || "blue.800";

  const LIGHTPLANMESSAGEBGCOLOR =
    chatConfig?.colors?.planMessageBackground?.light || "blue.100";
  const DARKPLANMESSAGEBGCOLOR =
    chatConfig?.colors?.planMessageBackground?.dark || "blue.900";

  const LIGHTCARDBGCOLOR = chatConfig?.colors?.cardBackground?.light || "white";
  const DARKCARDBGCOLOR =
    chatConfig?.colors?.cardBackground?.dark || "gray.700";

  const bgColor = useColorModeValue(LIGHTBGCOLOR, DARKBGCOLOR);
  const iconColor = useColorModeValue("Black", "White");
  const userMessageBgColor = useColorModeValue(
    LIGHTUSERMESSAGEBGCOLOR,
    DARKUSERMESSAGEBGCOLOR
  );
  const assistantMessageBgColor = useColorModeValue(
    LIGHTASSISTANTMESSAGEBGCOLOR,
    DARKASSISTANTMESSAGEBGCOLOR
  );
  const planMessageBgColor = useColorModeValue(
    LIGHTPLANMESSAGEBGCOLOR,
    DARKPLANMESSAGEBGCOLOR
  );
  const cardBgColor = useColorModeValue(LIGHTCARDBGCOLOR, DARKCARDBGCOLOR);
  const progressLightColorMode = "rgba(255, 255, 255, 0.8)";
  const progressColorMode = "rgba(255, 255, 255, 0.5)";
  const progressLightColor = useColorModeValue(
    "rgba(0, 0, 0, 0.8)",
    progressLightColorMode
  );
  const progressColor = useColorModeValue(
    "rgba(0, 0, 0, 0.3)",
    progressColorMode
  );

  const AttachmentSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path fill={iconColor} fillRule="evenodd" d="M9 7a5 5 0 0 1 10 0v8a7 7 0 1 1-14 0V9a1 1 0 0 1 2 0v6a5 5 0 0 0 10 0V7a3 3 0 1 0-6 0v8a1 1 0 1 0 2 0V9a1 1 0 1 1 2 0v6a3 3 0 1 1-6 0z" clipRule="evenodd"></path>
    </svg>
  );

  const websocketManager = new WebSocketSingleton();

  let eventSource = null;
  const communicationMethod = chatConfig.communicationMethod;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (pastThreads.length == 0) {
      setcreateNewThread(true);
    } else {
      setcreateNewThread(false);
    }
    cleanupConnection();
    if (
      chatConfig.communicationURL == undefined ||
      chatConfig.communicationURL == ""
    ) {
      setIsConnected(true); // for dummy
      return;
    }
    if (communicationMethod === "websocket") {
      connectWebSocket(chatConfig.communicationURL);
    } else if (communicationMethod === "sse") {
      eventSource = new EventSource(chatConfig.communicationURL);
      eventSource.onmessage = handleMessage;
      eventSource.onerror = (error) => {
        console.error("SSE error:", error);
      };
    }
  }, [chatConfig.communicationURL]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, isLoading]);

  const getSignedUrlForPdfs = async (formData) => {
		var filepath = formData.get('file');
		const timestamp = new Date().getTime();
		var filename = `${timestamp}_${filepath.name}`;

		// if (filepath.name > 0) {
			try {
				const subPath = "document_creator";
				const signMethod = "put";

				const reportBugApiUrl = `${baseDevasUrl}files/signed_urls/${subPath}/${signMethod}`;
				const signedUrlResponse = await fetch(reportBugApiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ "filenames": [filename] }),
				});
				if (!signedUrlResponse.ok) {
					throw new Error(`HTTP error! signedUrl status: ${signedUrlResponse.status}`);
				}
				const signedUrlJson = await signedUrlResponse.json();
				var signedUrls = signedUrlJson?.data;
				var signedUrl = signedUrls[filename];
				console.log("signedUrl >", signedUrl);
			} catch (err) {
				console.log("error with File upload !!", err);
			}
	}


  const handleFileChange = async (e) => {
    // console.log("Hi File Change added");
    setFileContent("");
    setSelectedFile("");
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 5242880) { // 2 MB in bytes
        alert("File size exceeds 2 MB limit.");
        return; // Stop further processing if file is too large
      }
      setSelectedFile(file.name);
    //   console.log("Selected file:", file.name);

      const formData = new FormData();
      formData.append("file", file); // Assuming the server expects a 'file' key
      // const response = await getSignedUrlForPdfs(formData)

      let attempts = 0;
      const maxAttempts = 3;
      let success = false;
      while (!success && attempts < maxAttempts) {
        try {
          const response = await fetch(
            `${baseDevasUrl}devas/util/pdf_to_text`,
            {
              method: "POST",
              body: formData,
            }
          );
          if (response.ok) {
            const data = await response.json(); // Parse the JSON response
            console.log("PDF-data",data)
            if (data.text) {
              setFileContent(data.text);
              success = true;
            } else {
              console.log(
                `Attempt ${
                  attempts + 1
                }: No PDF text content found, retrying...`
              );
              attempts++;
            }
          } else {
            console.error("Failed to convert PDF. Status: ", response.status);
            break; // Exit the loop if the response is not ok
          }
        } catch (error) {
          console.error("Error during API call:", error);
          break; // Exit the loop if there's an error
        }
      }

      if (!success) {
        console.error(
          "Failed to retrieve PDF text content after maximum attempts."
        );
      }
    }
  };

  const triggerFileInput = () => {
    // console.log("Trigger File Input");
    document.getElementById("attachment").blur();
    document.getElementById("fileInput").click();
  };

  const connectWebSocket = (communicationURL) => {
    const webSocketInstance = websocketManager.openWebSocket(communicationURL);
    setSocket(webSocketInstance);

    webSocketInstance.connection.onopen = () => {
      setIsConnected(true);
    };

    webSocketInstance.connection.onmessage = handleMessage;

    webSocketInstance.connection.onerror = (error) => {
      console.error("WebSocket error:", error);
      setIsConnected(false);
    };

    webSocketInstance.connection.onclose = () => {
      console.log("WebSocket connection closed");
      setIsConnected(false);
      setTimeout(connectWebSocket(communicationURL), 1000);
    };
  };

  useEffect(() => {
    console.log(isConnected);
    if (
      isConnected &&
      socket &&
      socket.connection.readyState == WebSocket.OPEN &&
      newThreadsendMessage != ""
    ) {
      socket.sendMessage(inputParser(newThreadsendMessage));
      setNewThreadsendMessage("");
    }
  }, [isConnected]);

  const cleanupConnection = () => {
    if (communicationMethod === "websocket") {
      if (socket && socket.connection) {
        socket.connection.onmessage = null;
        socket.connection.onerror = null;
        socket.connection.onclose = null;
        socket.closeWebSocket();
        // socket.connection.close();

        setIsConnected(false);
      }
    }
    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }
  };

  // Function to toggle side panel visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleInputChange = (e) => {
    console.log("handleInputChange");
    setInput(e.target.value);
    // Reset height to auto to correctly reduce size when deleting content
    setTextAreaHeight("");
  };
  const handleInput = (e) => {
    const textArea = e.target;
    // Reset height to auto to correctly reduce size when deleting content
    textArea.style.height = "";
    // Calculate the number of lines (rounded up) by dividing the scrollHeight by the line height in pixels
    const lines = Math.ceil(
      textArea.scrollHeight /
        (lineHeightEm * parseFloat(getComputedStyle(textArea).fontSize))
    );
    // Calculate the new height in em units
    const newHeightEm = lines * lineHeightEm;
    // Apply the new height, respecting the min and max height constraints
    textArea.style.height = `${Math.min(
      Math.max(newHeightEm, parseFloat(minHeight)),
      parseFloat(maxHeight)
    )}em`;
    
    console.log("New Height >>", `${Math.min(
      Math.max(newHeightEm, parseFloat(minHeight)),
      parseFloat(maxHeight)
    )}em`);
  };

  // Function to handle clicking on a past thread
  const handlePastThreadClick = (thread_id) => {
    // Trigger function passed as prop to load past thread

    const newCommunicationURL = chatConfig?.getCommunicationURL(thread_id);
    if (newCommunicationURL !== chatConfig.communicationURL) {
      setChatMessages([]);
      cleanupConnection();
      chatConfig.communicationURL = newCommunicationURL;
      setchatConfig(chatConfig);
      // connectWebSocket(newCommunicationURL);
      loadThreadMessages(thread_id);
    }

    setIsMenuOpen(false); // Close side panel after clicking a thread
  };

  const renderThreadList = (pastThreads) => {
    return pastThreads.map((thread, index) => (
      <ListItem
        key={index}
        cursor="pointer"
        onClick={() => handlePastThreadClick(thread.id)}
      >
        <ListIcon as={ArrowForwardIcon} color="teal.500" />
        {thread.title}
      </ListItem>
    ));
  };

  const createChatThreadWrapper = async () => {
  setMarkdownContent("");

    if (chatMessages.length < 1) {
      return;
    }
    setChatMessages([]);
    setcreateNewThread(true);
  };
  useEffect(() => {
    console.log("Selected file: Changes ", selectedFile);
  }, [selectedFile]);

  const handleSend = async (event) => {
    event.preventDefault();
    if (!input.trim()) {
      return;
    }

    setInput("");
    setSelectedFile("");
    setFileContent("");
    setTextAreaHeight("");
    document.querySelector("textarea").style.height = "3em";
    setIsLoading(true);
    // console.log("input", input);
    let message = input
    if (selectedFile) {
        // console.log("selectedFile",selectedFile)
        message = JSON.stringify({
          message: input,
          type: "attachment",
          file_name: selectedFile,
          file_content: fileContent,
        });
        setSelectedFile("");
        setFileContent("");
    }

    if (createNewThread) {
      const thread_id = await createChatThread(input.substring(0, 8) + "...");
      setNewThreadsendMessage(message);
    }
    const newMessages = [...chatMessages, inputmessageParser(message)];
    setChatMessages(newMessages);
    // console.log("message", message);
    if (
      communicationMethod === "websocket" &&
      socket &&
      socket.connection.readyState === WebSocket.OPEN &&
      !createNewThread
    ) {
        // console.log("s/* */ocket_message",message)
      socket.sendMessage(message);
    } else if (communicationMethod === "sse") {
      const response = await sendSSEMessage(
        newMessages,
        "openai-gpt4",
        true,
        "ushankradadiya@gofynd.com"
      );
      const contentType = response.headers.get("Content-Type");
      try {
        if (contentType && contentType.startsWith("text/event-stream")) {
          if (response.body) {
            let index = 0;
            const resp = { data: "" };
            for await (const chunk of response.body) {
              setIsLoading(false);
              index += 1;
              const decoder = new TextDecoder("utf-8");
              const str = decoder.decode(chunk);
              const data = str.split("data: ");
              data.forEach((element) => {
                if (element) {
                  const jsonData = JSON.parse(element);
                  if (jsonData?.choices[0]["finish_reason"] !== "stop") {
                    const text = jsonData.choices[0].delta.content;
                    resp.data += text;
                    setChatMessages([
                      ...newMessages,
                      { content: resp.data, role: "system", isUser: false },
                    ]);
                  }
                }
              });
            }
          }
        } else {
          const esp = await response.json();
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setcreateNewThread(false);
  };

  const handleMessage = (event) => {
    const output_data = event.data;
    const message = outputParser(output_data);
    if (message) {
      console.log(message);
      if (message?.meta?.progress) {
        setProgressMessage(message.content);
      } else {
        setProgressMessage("");
        setChatMessages((prev) => {
          if (prev.length > 0) {
            let lastMessage = prev[prev.length - 1];
            if (message?.meta?.stream) {
              if (!lastMessage?.meta?.end_of_message) {
                lastMessage = {
                  ...lastMessage,
                  content: message.content,
                  meta: message?.meta,
                };
                return [...prev.slice(0, -1), lastMessage];
              } else {
                return [...prev, message];
              }
            } else {
              return [...prev, message];
            }
          }
          return [...prev, message];
        });
      }
    }
    setIsLoading(false);
  };

  const sendSSEMessageDemo = async (message) => {
    try {
      const response = await fetch(chatConfig.communicationURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action_id: 1,
          data: {
            messages: [
              {
                role: "user",
                content: "Hi",
              },
            ],
          },
          model: "openai-gpt4",
          stream: true,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send message via SSE");
      }
    } catch (error) {
      console.error("Error sending SSE message:", error);
    }
  };
  const handleRemoveFile = () => {
    setSelectedFile("");
    setFileContent("");
  };

  const sendSSEMessage = async (
    messages,
    fexModel,
    stream = false,
    userEmail
  ) => {
    const url = chatConfig.communicationURL;
    const myHeaders = { "Content-Type": "application/json" };
    const lastMessage = messages[messages.length - 1];

    const raw = JSON.stringify({
      action_id: 1,
      data: {
        messages: messages,
      },
      model: fexModel,
      stream: stream,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    console.log("requestOptions >>", requestOptions);
    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      console.log("response >>", response);
      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Create a custom icon component using the provided SVG
const AttachmentIcon = createIcon({
  displayName: 'AttachmentIcon',
  viewBox: '0 0 36 36',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" width="36" height="36">
      <rect width="36" height="36" rx="6" fill="#FF5588" />
      <path
        d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.667 9.66663V14.6666H24.667"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3337 18.8334H14.667"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3337 22.1666H14.667"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3337 15.5H15.5003H14.667"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  }); 

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      borderRadius="lg"
      overflow="hidden"
      background="#fff"
      bg={cardBgColor}
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        {/* Hamburger menu icon */}
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          onClick={toggleMenu}
          // display={{ base: 'block', md: 'none' }} // Show only on small screens
        />
        {/* Conditionally render past threads in side panel */}
        <Drawer
          placement="left"
          onClose={() => setIsMenuOpen(false)}
          isOpen={isMenuOpen}
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton colorScheme="teal"
            _hover={{
              bg: "teal.600",
              transform: "scale(1.1)",
              shadow: "md",
            }} 
            _active={{
                bg: "teal.700",
                transform: "scale(1)",
                shadow: "sm",
              }}/>
              <DrawerHeader>Past Threads</DrawerHeader>
              <DrawerBody>
                <List spacing={2}>{renderThreadList(pastThreads)}</List>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
        <Heading fontSize="xl" ml={5}>Chat</Heading>
        <Spacer />
        <Button
          bg={cardBgColor}
          onClick={createChatThreadWrapper}
          isDisabled={chatMessages.length < 1}
        >
          <AddIcon />
        </Button>
        <Flex alignItems="center">
          <Box
            size="12px"
            bg={isConnected ? "green.500" : "red.500"}
            borderRadius="full"
            w={4}
            h={4}
            animation={isConnected ? `${pulse} 1.5s infinite` : "none"}
            mr={2}
          />
          <Text>{isConnected ? "Active" : "Inactive"}</Text>
        </Flex>
        <IconButton
          ml={4}
          onClick={toggleColorMode}
          icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
          aria-label="Toggle color mode"
        />
      </Flex>
      <Divider my={4} />
      <VStack
        spacing={4}
        overflowY="auto"
        h="calc(100vh - 200px)"
        p={3}
        bg={bgColor}
        borderRadius="lg"
      >
        {/* Render current chat messages */}
        {renderMessages(chatMessages)}
        <Box w="100%" textAlign="left" ml={10}>
          {progressMessage && (
            <Text
              textAlign="left"
              background={`linear-gradient(135deg, ${progressLightColor} 0%, ${progressLightColor} 20%, ${progressColor} 50%, ${progressLightColor} 80%, ${progressLightColor} 100%)`}
              backgroundSize="300% 300%"
              animation={`${progresswave} 5s linear infinite`}
              backgroundClip="text"
              textFillColor="transparent"
            >
              {progressMessage}
            </Text>
          )}
        </Box>
        {isLoading && (
          <Box p={3} borderRadius="md" alignSelf="flex-start" display="flex">
            <Box display="flex" alignItems="center" justifyContent="center">
              {Array.from({ length: 4 }).map((_, idx) => (
                <Box
                  key={idx}
                  as="span"
                  mx="4px"
                  height="10px"
                  width="10px"
                  borderRadius="full"
                  bg={
                    idx === 0
                      ? "red.500"
                      : idx === 1
                      ? "blue.500"
                      : idx === 2
                      ? "green.500"
                      : idx === 3
                      ? "yellow.500"
                      : "orange.500"
                  }
                  animation={`${wave} 1.3s ease-in-out infinite`}
                  style={{ animationDelay: `${idx * 0.1}s` }}
                ></Box>
              ))}
            </Box>
          </Box>
        )}
        <Box ref={messagesEndRef} />
      </VStack>

      <Flex mt={4}>
        <form
          onSubmit={handleSend}
          style={{ 
            width: "100%", 
            display: "flex", 
            alignItems: "center"
          }}
        >
          <Box position="relative" flex="3" bg={bgColor}
            style={{ 
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
              boxShadow: "0 2px 6px rgba(0,0,0,0.1)", 
              padding: "0.5rem 0.5rem 0.5rem 0.5rem"
            }}>
            <HStack 
              style={{
                alignItems: "flex-end",
              }}
            >
              <Tooltip hasArrow label="Attach File" placement="top">
                <IconButton
                  id="attachment"
                  icon={ <AttachmentSvg/> }
                  colorScheme={iconColor}
                  size="md"
                  _hover={{
                    bg: "none",
                    transform: "scale(1.1)",
                  }}
                  _active={{}}
                  bg="none"
                  // shadow="sm"
                  aria-label="Upload PDF"
                  isDisabled={isLoading}
                  onClick={triggerFileInput}
                  mb={2}
                />
              </Tooltip>
              <VStack style={{
                width: "100%",
                alignItems: "flex-start",
                gap: 0
              }}>
                <Box>
                  <Box>
                    <Flex gap={2} w="100%" direction="row" wrap="wrap">
                    {selectedFile && <Flex 
                        direction="row" 
                        alignItems="center" 
                        mb={2} pb={2} pr={2} pl={2} pt={1} 
                        alignSelf = "end" bg={cardBgColor} 
                        gap={3}
                        style={{
                          border: `1px solid ${bgColor}`, 
                          borderRadius: '10px', 
                          boxShadow: "0 2px 6px rgba(0,0,0,0.2)" 
                          }}
                        >
                        <Icon as={AttachmentIcon} boxSize={10} />
                        <Box>
                            <Text fontWeight="500" isTruncated>{selectedFile}</Text>
                            <Text color="gray.400" fontSize="sm" isTruncated>PDF</Text>
                        </Box>
                        <Tooltip hasArrow label="Remove file" placement="top" borderRadius={5}>
                          <Icon
                            as={CloseIcon}
                            onClick={handleRemoveFile}
                            cursor="pointer"
                            position="relative"
                            float="right"
                            size="sm"
                            border="1px solid #ccc"
                            borderRadius="50%"
                            p="2px"
                          />
                        </Tooltip>
                      </Flex>}
                    </Flex>
                  </Box>
                </Box>
                <Textarea
                  placeholder="Type your message here"
                  value={input}
                  bg={bgColor}
                  variant=""
                  onChange={handleInputChange}
                  onInput={handleInput}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!e.shiftKey) {
                        e.preventDefault(); // Prevent form submission
                        document
                          .querySelector("form")
                          .dispatchEvent(
                            new Event("submit", { cancelable: true, bubbles: true })
                          ); // Programmatically submit the form
                      } else if (e.shiftKey) {
                        e.preventDefault(); // Prevent form submission
                        setInput(e.target.value + "\n");
                        handleInput(e)
                        // Scroll to the bottom of the textarea
                        setTimeout(() => {
                          e.target.scrollTop = e.target.scrollHeight;
                        }, 0); // Timeout ensures the scroll happens after the textarea updates
                      }
                    }
                  }}
                  style={{
                    minHeight: minHeight,
                    height: "3em",
                    overflowY: "scroll",
                    width: "100%",
                    padding: "0.525rem 0.525rem 0.425rem 0", // Adjust left padding to accommodate icon and file label
                    outline: "none", // Removes the focus outline
                    border: "none",
                    borderRadius: "0",
                    resize: "none",
                    fontSize: "1rem"
                  }}
                  isDisabled={isLoading}
                />
              </VStack>
              <IconButton
                icon={<ArrowForwardIcon />}
                colorScheme="purple"
                ml={2}
                mb={2}
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading}
                rounded="full"
                size="md"
                _hover={{
                  bg: "purple.400",
                  transform: "scale(1.01)",
                  shadow: "md",
                }}
                _active={{
                  bg: "purple.400",
                  transform: "scale(1)",
                  shadow: "sm",
                }}
                shadow="sm"
                aria-label="Send Message"
              />
            </HStack>
          </Box>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept=".pdf"
            onChange={handleFileChange}
          />
        </form>
      </Flex>
    </Box>
  );
};

// Defined default parser functions
const defaultInputParser = (input) => {
  return input.trim();
};

const defaultInputMessageParser = (messageData) => {
  return {
    role: "user",
    is_json: true,
    content: messageData,
    meta: { end_of_message: true },
  };
};

// Assign default props
ChatComponent.defaultProps = {
  inputParser: defaultInputParser,
  inputmessageParser: defaultInputMessageParser,
};

export default ChatComponent;
