import createRoutes from '../utils/utilities';
import LandingPage from './landingPage';


export const genericRoutes = {
    products: {
        name: "Home",
        path: "/products",
        component: LandingPage,
        private: true,
        isActive: false
    },
};

const GenericRoutes = createRoutes({ routes: genericRoutes });
export default GenericRoutes;
