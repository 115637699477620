import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Center, Button, Image, VStack, FormControl, Input, Box, Flex, Text, Icon, useToast, Stack, HStack, Link, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import { baseHiruzenInternalUrl, baseNejiUrl } from '../../utils/constants';

export const RegistrationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const [phonenumber, setPhonenumber] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const code = queryParams.get('inviteCode');

    useEffect(() => {
        fetchInviteDetails();
    }, []);

    const fetchInviteDetails = async () => {
        const url = `${baseHiruzenInternalUrl}role/get-invite-details?inviteCode=${code}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                setRegisterEmail(data.result.data.email);
            } else {
                throw new Error('Failed to fetch invite details');
            }
        } catch (error) {
            console.error('Error fetching invite details:', error);
            toast({
                title: 'Error',
                description: error.message || 'An unknown error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
        }
    };

    const handleRegister = async () => {
        const url = `${baseNejiUrl}authentication/register`;
        const body = { firstName: firstname, lastName: lastname, phoneNumber: phonenumber, password: registerPassword, inviteCode: code };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            });

            const data = await response.json();
            if (response.ok) {
                toast({
                    title: 'Registration Successful',
                    description: "User created successfully",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                });
                navigate("/")
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message || 'An unknown error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
        }
    };

    const passwordsMatch = registerPassword === confirmPassword;
    const allFieldsFilled = firstname && lastname && registerEmail && phonenumber && registerPassword && confirmPassword;

    return (
        <Center h="100vh" w="100vw" p={4}>
            <Box p={{ base: 4, md: 8 }} borderWidth={2} borderRadius="xl" bg="white" maxW={{ base: '90%', sm: '400px', md: 'md' }}>
                <VStack spacing={{ base: 4, md: 6 }} align="stretch">
                    <Flex align="center" justifyContent="center" mb={5}>
                        <svg width="50" height="50" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1073_26659)">
                            <path d="M5.14498 0.59082H1.74123H1.73073V0.60832L0.00523376 3.64282L1.73073 6.59332L3.46148 3.55007H11.522L13.9947 0.59082H5.14498Z" fill="#A380FF"/>
                            <path d="M0.909912 10.459L2.63541 13.4095L4.36616 10.3662L5.81866 7.81472H9.00366L10.7992 4.85547H4.10366L0.909912 10.459Z" fill="#A380FF"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1073_26659">
                            <rect width="13.9895" height="12.8188" fill="white" transform="translate(0.00523376 0.59082)"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="normal" ml={3}sx={{
                                background: '#A380FF',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundClip: 'text',
                            }}>FyniX</Text>
                    </Flex>
                    <Stack spacing={{ base: 4, md: 6 }} width="100%">
                        <HStack spacing={{ base: 2, md: 6 }}>
                            <FormControl id="firstname">
                                <Input type="text" focusBorderColor='purple.400' value={firstname} onChange={(e) => setFirstname(e.target.value)} placeholder="First Name" size="lg" variant="filled" />
                            </FormControl>
                            <FormControl id="lastname">
                                <Input type="text" focusBorderColor='purple.400' value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder="Last Name" size="lg" variant="filled" />
                            </FormControl>
                        </HStack>
                        <FormControl id="phonenumber">
                            <Input type="tel" focusBorderColor='purple.400' value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} placeholder="Phone Number" size="lg" variant="filled" />
                        </FormControl>
                        <FormControl id="register-email">
                            <Input type="email" focusBorderColor='purple.400' value={registerEmail} placeholder="Email" size="lg" variant="filled" disabled="true" />
                        </FormControl>
                        <FormControl id="register-password">
                            <Input type="password" focusBorderColor='purple.400' value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} placeholder="Password" size="lg" variant="filled" />
                        </FormControl>
                        <FormControl id="confirm-password">
                            <Input type="password" focusBorderColor='purple.400' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" size="lg" variant="filled" /> 
                            <Flex alignItems="center" mt={2}>
                                {passwordsMatch && confirmPassword && (
                                    <>
                                        <Icon as={FcCheckmark} boxSize={6} color="green.500" />
                                        <Text ml={2} color="green.500">Passwords match</Text>
                                    </>
                                )}
                                {!passwordsMatch && registerPassword && confirmPassword && (
                                    <>
                                        <Icon as={FcCancel} boxSize={6} color="red.500" />
                                        <Text ml={2} color="red.500">Passwords not matching</Text>
                                    </>
                                )}
                            </Flex>
                        </FormControl>
                        <Button
                            colorScheme="purple"
                            variant="solid"
                            py={5}
                            rightIcon={<ArrowRightIcon />}
                            onClick={handleRegister}
                            isDisabled={!(allFieldsFilled && passwordsMatch)}
                        >
                            Sign Up
                        </Button>
                    </Stack>
                </VStack>
            </Box>
        </Center>
    );
};
