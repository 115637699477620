import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { defaultScreenshot } from "../../utils/screenshot";
import { baseDevasUrl, baseDevasWebsocketUrl } from "../../utils/constants"
import MermaidDiagram from "../common/MermaidDiagram"; 
import mermaid from 'mermaid';
import { saveAs } from 'file-saver';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import CustomBreadcrumb from '../common/CustomBreadcrumb';


import {
    Box,
    Button,
    Input,
    Text,
    VStack,
    HStack,
    Flex,
    Heading,
    Divider,
    keyframes,
    useColorModeValue,
    useColorMode,
    IconButton,
    Spacer,
    Image,
    ListItem,
    ListIcon,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import ChatComponent from "../common/ChatComponent";
import { diagramCreatorRoutes } from './routes';
import { genericRoutes } from '../routes';

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.7);
  }
`;


function SequenceDiagram(props) {
    let { projectId } = useParams();
    const productId = 3
    const navigate = useNavigate();
    const breadcrumbItems = [
        { label: genericRoutes.products.name, path: genericRoutes.products.path, isCurrentPage: false },
        { label: diagramCreatorRoutes.diagramProjects.name, path: diagramCreatorRoutes.diagramProjects.path, isCurrentPage: false },
        { label: diagramCreatorRoutes.diagramProjectPage.name, path: diagramCreatorRoutes.diagramProjectPage.path, pathParams: { projectId },
            isCurrentPage: true }
    ];
    const location = useLocation();
    const { gitUrl, quipLinks } = location.state || { gitUrl: '', quipLinks: [] };
    const { brdData } = location.state || { brdData: '' };
    const { toggleColorMode, setColorMode, colorMode } = useColorMode();
    let brdSentOnce = false;
    const [socket, setSocket] = useState(null);
    const [userMessage, setUserMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [markdownContent, setMarkdownContent] = useState(""); // State to store Markdown content
    const [mermaidContent, setMermaidContent] = useState(""); // State to store Markdown content
    
    const [terminalHistory, setTerminalHistory] = useState([]);
    const [mermaidHistory,setMermaidHistory] = useState([]); // State to store mermaid content
    const [currentCommand, setCurrentCommand] = useState('');
    const [url, setUrl] = useState(null);
    const [screenshot, setScreenshot] = useState(defaultScreenshot);
    const [pastThreads, setPastThreads] = useState([]); // State to store past threads fetched from API
    const [chatConfig, setchatConfig] = useState('');


    const bgColor = useColorModeValue("gray.50", "gray.800");
    const cardBgColor = useColorModeValue("white", "gray.700");
    const planMessageBgColor = useColorModeValue('blue.100', 'blue.900');
    const userMessageBgColor = useColorModeValue('green.100', 'green.800');
    const assistantMessageBgColor = useColorModeValue('blue.100', 'blue.800');
    const mermaidBgColor = useColorModeValue("white", "gray.100");
    const inputBgColor = useColorModeValue("white", "gray.600");
    const svgContainerRef = useRef(null); // Ref for the hidden div
    const [exportFormat, setExportFormat] = useState('svg');
    const [undoHistory, setUndoHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level (percent)
    const [threadId,setThreadId] = useState(null);
    const [hasAccess, setHasAccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [projectDetails, setProjectDetails] = useState({});

    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => prevZoomLevel * 1.1); // Increase zoom by 10%
    };

    const handleZoomOut = () => {
        setZoomLevel((prevZoomLevel) => prevZoomLevel / 1.1); // Decrease zoom by 10%
    };

    const openNewURL = (thread_id) => {
        window.open(`https://diagramcreator.fexz0.de/${thread_id}`, '_blank');
    };
    const handleThreadIdChange = (newThreadId) => {
        console.log("thread_id",newThreadId)
        setThreadId(newThreadId);
    };


    const handleFormatChange = (event) => {
        setExportFormat(event.target.value);
    };
    const handleUndo = () => {
        if (undoHistory.length > 0) {
            const previousContent = undoHistory.pop();
            setRedoHistory([...redoHistory, mermaidContent]);
            setMermaidContent(previousContent);
            setUndoHistory([...undoHistory]);
        }
    };

    const handleRedo = () => {
        if (redoHistory.length > 0) {
            const nextContent = redoHistory.pop();
            setUndoHistory([...undoHistory, mermaidContent]);
            setMermaidContent(nextContent);
            setRedoHistory([...redoHistory]);
        }
    };

    
    

    const getThreadsApi = `${baseDevasUrl}projects/${projectId}/threads?product_id=${productId}`;
    const handleExport = async (format) => {
        const { svg } = await mermaid.render('mermaid-diagram-' + new Date().getTime(), mermaidContent);
        const svgContainer = svgContainerRef.current;
        if (svgContainer) {
            svgContainer.innerHTML = svg;
            const svgElement = svgContainer.querySelector('svg');
            if (svgElement) {
                if (exportFormat === 'svg') {
                    const svgData = new Blob([svgElement.outerHTML], { type: 'image/svg+xml;charset=utf-8' });
                    saveAs(svgData, 'diagram.svg');
                } else if (exportFormat === 'png') {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const img = document.createElement('img');
                    // const img = new Image();
                    img.onload = () => {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        canvas.toBlob((blob) => {
                            saveAs(blob, 'diagram.png');
                        });
                    };
                    img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgElement.outerHTML);
                }
            }
        }
    };
    
    

    let tempchatConfig = {
        communicationURL: brdData.length > 0 ? `${baseDevasWebsocketUrl}devas/chat/mermaid_builder/${projectId}` : '',
        communicationMethod: 'websocket',
        getCommunicationURL: (thread_id) => `${baseDevasWebsocketUrl}devas/chat/mermaid_builder/${thread_id}`,
        isCrateNewSocket: false,
        socket: socket,
        colors: {
            background: {
                light: "gray.50",
                dark: "gray.800"
            },
            userMessageBackground: {
                light: "green.100",
                dark: "green.800"
            },
            assistantMessageBackground: {
                light: "blue.100",
                dark: "blue.800"
            },
            planMessageBackground: {
                light: "blue.100",
                dark: "blue.900"
            },
            cardBackground: {
                light: "white",
                dark: "gray.700"
            }
        }
    };
    

    const fetchPastThreads = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(getThreadsApi, {credentials: 'include'});
            if (!response.ok) {
                throw new Error('Failed to fetch past threads');
            }
            const response_data = await response.json();
            console.log("response_data >> ", response_data);
            setIsLoading(false);
            if (response_data.data) {
                setProjectDetails(response_data.data)
                const threads = response_data.data.threads
                console.log("Threads >> ", threads);
                if (threads.length > 0){
                    threads.sort((x, y) => y.id - x.id);
                    setPastThreads(threads);
                    loadThreadMessages(threads[0].id);
                    tempchatConfig.communicationURL = `${baseDevasWebsocketUrl}devas/chat/mermaid_builder/${threads[0].id}`
                    setchatConfig(tempchatConfig);
                }
            } else {
                setHasAccess(false);
            }
        } catch (error) {
            console.error('Error fetching past threads:', error);
        }
    };

    const loadThreadMessages = (thread_id) => {
        setThreadId(thread_id)
        // setTerminalHistory([]);
        // setMermaidHistory([])
        setMermaidContent("")
        setChatMessages([]);
        fetch(`${baseDevasUrl}threads/${thread_id}/messages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                // conso
                // console.log(response);
                // console.log(response.data);
                if (response.success && response.data.length > 0) {
                    const messages = response.data
                    // console.log('messages >>', messages);
                    for (const message of messages) {
                        // console.log("message >>", message);
                        // console.log("chatMessages >>", chatMessages);
                        if (message.is_json) {
                            const data = JSON.parse(message.content);
                            console.log("all data=",data)
                            if (data.window === 'terminal') {
                                updateTerminal(data.message);
                            }
                            else if(data.window === 'mermaid'){
                                console.log("mermaid_data",data.message)
                                setMermaidContent(data.message)
                            }
                            else if (data.window === 'browser') {
                                updateBrowser(data);
                            } else if (data.type === 'plan') {
                                receiveMessage(data.message, 'plan');
                            } else {
                                receiveMessage(data.message, 'text');
                            }
                        } else {
                            setChatMessages((previousChat) => [...previousChat, message]);
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };

    const createChatThread = async (name) => {
        setChatMessages([]);
        const response = await fetch(`${baseDevasUrl}threads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    "name": name,
                    "requested_by": localStorage.getItem("email"),
                    "alternate_id":`${projectId}`
            }),
        });

        if (response.ok) {
            const response_data= await response.json()
            await fetchPastThreads();
            const thread_id = response_data.data.thread_id;
            return thread_id;
        }
    };


    useEffect(() => {
        setColorMode("light");
        setchatConfig(tempchatConfig);
        // createProject();
        fetchPastThreads();
        setThreadId(threadId)
    }, []);


    // browser
    const handleInputChange = (event) => {
        setUrl(event.target.value);
    };

    const updateBrowser = (data) => {
        if (data.type === "link") {
            setUrl(data.message.link);
        } else if (data.type === "image") {
            setScreenshot(data.message.image);
        }
    }

    const receiveMessage = (message, type) => {
        if (type === 'plan') {
            const steps = Object.entries(message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            setChatMessages((prev) => [...prev, { role: "assistant", content: steps, type: 'plan' }]);
        } else {
            setChatMessages((prev) => [...prev, { role: "assistant", content: message, type: 'text' }]);
        }
    };

    const updateTerminal = (data) => {
        setTerminalHistory((prevHistory) => {
            if (prevHistory.length > 0) {
                const lastEntry = prevHistory[prevHistory.length - 1];
                const isSameCommand = lastEntry.command === data.command;

                if (isSameCommand) {
                    // Append new output to the latest command output
                    const updatedHistory = [...prevHistory];
                    updatedHistory[updatedHistory.length - 1].output += "\n" + data.output; // Append new output
                    return updatedHistory;
                }
            }
            // New command or not recent, add to history
            return [
                ...prevHistory,
                { command: data.command, output: data.output }
            ];
        });
        setCurrentCommand('');
    };
    const updateMermaid = (data) => {
        setMermaidHistory((prevHistory) => {
            if (prevHistory.length > 0) {
                const lastEntry = prevHistory[prevHistory.length - 1];
                const isSameCommand = lastEntry.command === data.command;

                if (isSameCommand) {
                    // Append new output to the latest command output
                    const updatedHistory = [...prevHistory];
                    updatedHistory[updatedHistory.length - 1].output += "\n" + data.output; // Append new output
                    return updatedHistory;
                }
            }
            // New command or not recent, add to history
            return [
                ...prevHistory,
                { command: data.command, output: data.output }
            ];
        });
        // setCurrentCommand('');
    };

    // Define these functions in a separate file or at the top of your current file
    const inputParser = (input) => {
        return input.trim()
    };

    const outputParser = (output_data) => {
        // Example: Add a prefix to the output message
        const data = JSON.parse(output_data);
        if (data.window === 'mermaid'){
            // setMermaidContent("flowchart TD\n    A[Start] --> B[Choose vegetables]\n    B --> C[Wash vegetables]\n    C --> D[Cut vegetables]\n    D --> E[Cook vegetables]\n    E --> F[Season vegetables]\n    F --> G[Vegetables ready]\n    G --> H[End]")
            setMermaidContent(data.message)
        }
        else if (data.window === 'terminal') {
            updateTerminal(data.message);
        } else if (data.window === 'browser') {
            updateBrowser(data);
        } else if (data.type === 'plan') {
            const steps = Object.entries(data.message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            return { role: "assistant", content: steps, type: 'plan',  meta: data.meta || {}  };
        } else {
            return { role: "assistant", content: data.message, type: 'text',  meta: data.meta || {} };
        }
        
    };

    const inputmessageParser = (messageData) => {
        return { role: "user", content: messageData };
    };

    const renderMessages = (chatMessages) => {
        return chatMessages.map((msg, index) => (
            msg.type === 'plan' ? (
                <Box key={index} bg={planMessageBgColor} p={4} borderRadius="md" alignSelf="flex-start">
                    {msg.content.split("\n").map((line, idx) => (
                        <Text key={idx} fontSize="md" mb={2}>{line}</Text>
                    ))}
                </Box>
            ) : (
                <Text key={index} bg={msg.role === "user" ? userMessageBgColor : assistantMessageBgColor} p={3} borderRadius="md" alignSelf={msg.role === "user" ? "flex-end" : "flex-start"} maxWidth="80%" > {msg.content}</Text>
            )
        ));
    };

    return (
        <Box>
            <Box
                h="100vh"
                w="full"
                position="absolute"
                zIndex="overlay"
                bgColor="blackAlpha.800"
                justifyContent="center"
                alignItems="center"
                display={(hasAccess) ? 'none' : 'flex'}
            >
                {   (!hasAccess) && 
                    <VStack spacing={4} textAlign="center" color="white">
                        <HStack mb={-10}>
                            <Text fontSize="200px" color="gray.400" fontWeight="1000">4</Text>
                            <Image src="/restriction.png" alt="Access Denied" w="180px" mr={-2} ml={-2}/>
                            <Text fontSize="200px" color="gray.400" fontWeight="1000">3</Text>
                        </HStack>
                        <Text fontSize="40px" color="red.400" fontWeight="500" fontFamily="Lucida Console, Courier, monospace">Access Denied</Text>
                        <Text>You do not have permission to view this page, or the page may not be available</Text>
                        <Button colorScheme="teal" onClick={() => navigate(-1)}>
                            Go Back
                        </Button>
                    </VStack>
                }
            </Box>
            {hasAccess && <Flex h="100vh" p={4} bg={bgColor}>
                <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} mr={4} maxWidth="50%">
                    <CustomBreadcrumb items={breadcrumbItems}></CustomBreadcrumb>
                    <ChatComponent
                    
                        chatConfig={chatConfig}
                        setchatConfig={setchatConfig}
                        inputParser={inputParser}
                        outputParser={outputParser}
                        inputmessageParser={inputmessageParser}
                        chatMessages={chatMessages}
                        renderMessages={renderMessages}
                        setChatMessages={setChatMessages}
                        loadThreadMessages={(thread_id) => loadThreadMessages(thread_id)}
                        createChatThread={(name) => createChatThread(name)}
                        pastThreads={pastThreads}
                        setPastThreads={setPastThreads}
                        setMarkdownContent={setMarkdownContent}
                    />
                </Flex>
                <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={mermaidBgColor} maxW="1200px" width="100%"  style = {{"overflow":"auto"}} >
                    <Flex justifyContent="space-between" alignItems="center" mb={4} style = {{"top":"30px"}}>
                        <Heading fontSize="xl" color="black">Mermaid Diagram Viewer</Heading>
                        <Spacer />
                        <IconButton mr = "4px" icon={<AddIcon />} colorScheme="blue" onClick={handleZoomIn} aria-label="Zoom in" />
                        <IconButton icon={<MinusIcon />} colorScheme="red" onClick={handleZoomOut} aria-label="Zoom out" />
                    </Flex>
                    <Flex flexGrow={1} width="100%" overflow="auto" mt="10%">
                        <div style={{ transform: `scale(${zoomLevel / 100})`, transformOrigin: '0 0' }}>
                            {mermaidContent ? <MermaidDiagram text={mermaidContent} id_index="1" /> : ''}
                        </div>
                    </Flex>
                    {/* <Flex flexGrow={1} width="100%" overflow="auto" mt="10%">
                    {
                        mermaidContent ? <Mermaid text={mermaidContent} id_index="1" /> : ''
                    }
                    </Flex > */}
                        
                    <div ref={svgContainerRef} style={{ display: 'none' }}></div>
                    <Flex style = {{"position":"absolute","bottom":"30px","right":"10px"}}> 
                    <select value={exportFormat} onChange={handleFormatChange}>
                        <option value="svg">SVG</option>
                        <option value="png">PNG</option>
                    </select>
                    <Button onClick={handleExport} bg="brown">Export</Button>
                    <Button onClick={() => threadId && openNewURL(threadId)} colorScheme="blue" ml="2" isDisabled={!threadId}>
                    Open Diagram Creator
                    </Button>
                </Flex>
                </Flex>
            </Flex>}
        </Box>
    );
}

export default SequenceDiagram;
