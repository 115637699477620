import createRoutes from '../../utils/utilities';
import { AuthPage } from './LoginSignup';
import { OAuthCallback, MicrosoftOauthCallback } from './OAuth';
import { RegistrationPage } from './NewInvitedUserSignUp';
import VerificationSuccess from './VerificationSuccess';
import EmailVerification from './VerificationCallback';
import ForgotPasswordForm from './ForgotPassword';

export const authRoutes = {
    authPage: {
        name: "Login",
        path: "/",
        component: AuthPage,
        private: false
    },
    googleOauthCallback: {
        name: "Goggle OAuth Callback",
        path: "/oauth-callback",
        component: OAuthCallback,
        private: false
    },
    microsoftOauthCallback: {
        name: "Microsoft OAuth Callback",
        path: "/microsoft-oauth-callback",
        component: MicrosoftOauthCallback,
        private: false
    },
    userRegistration: {
        name: "Registration",
        path: "/auth/signup",
        component: RegistrationPage,
        private: false
    },
    emailVerification: {
        name: "Email Verification",
        path: "/auth/emailVerification",
        component: EmailVerification,
        private: false
    },
    verificationSuccess: {
        name: "Verification Success",
        path: "/verification-success",
        component: VerificationSuccess,
        private: false
    },
    forgotPasswordForm: {
        name: "Forgot Password",
        path: "/auth/setPassword",
        component: ForgotPasswordForm,
        private: false
    },
};

const AuthRoutes = createRoutes({ routes: authRoutes });
export default AuthRoutes;